<!--  -->
<template>
  <div class="content">
    <div class="inner">
      <div class="tit">开发者工具</div>
      <div class="project layout-flex-all">
        <div class="item">
          <img src="@/assets/img/tool_img01.png" alt />
          <span>接口调试工具</span>
        </div>
        <div class="item">
          <img src="@/assets/img/tool_img02.png" alt />
          <span>JSAPI签名工具</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  mounted: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.content {
  background: $bg;
  padding: 60px 0;
  .inner {
    padding: 140px 320px;
    width: $minWidth;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    box-sizing: border-box;
    .tit {
      margin-bottom: 70px;
      font-size: 26px;
      color: #282c47;
    }
    .project {
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        img {
          margin-bottom: 20px;
          width: 70%;
        }
        span {
          font-size: 30rpx;
          color: #282c47;
        }
      }
    }
  }
}
</style>